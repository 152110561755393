<template>
  <div v-loading.fullscreen.lock="loading">
      <div class="header-section">
          <div class="login-logo mb-4">
              <img class="logo_img" src="/assets/images/logo-img.png"/>
          </div>
          <div class="toplogin-btn">
              <router-link class="base-button btn btn-default login-text px-2" to="/login">Login</router-link>
          </div>
          <div class="toplogin-btn">
              <router-link class="base-button btn btn-default mr-2 px-2 signup-text" to="/signup">Sign up</router-link>
          </div>
      </div>
    <!--Banner Section--->
    <section class="banner-section">
      <div class="container">
        <div class="banner-caption">
          <h2>{{ tour.title }}</h2>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <!-- <div class="balloonimg balloonimg1"> -->
    <div class="balloonimgnew">
      <!-- <div class="balloonimg-two">
        <img src="marketing/images/p-slidebg.png" class="img-fluid" alt="" />
      </div> -->
      <div class="container">
       <div class="row">
          <div class="col-lg-10 offset-lg-1 col-12">
            <!-- <h3 class="text-center mb-5 mt-4">{{ tour.title }}</h3> -->
            <div class="TourVideo_section">
                <iframe  v-if="tour.videoUrl" :src="'https://player.vimeo.com/video/' + tour.videoUrl" style="border: 0;" width="100%" height="520" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="allowfullscreen" class="iframe-broder text-center">
              </iframe>
            </div>

          </div>
        </div>
      </div>
    </div>
      <the-footer/>
  </div>

</template>
<script>
import TheFooter from '@/views/Layout/AppFooter.vue'
// import LoginInput from "@/views/Pages/LoginInput.vue";
import "sweetalert2/src/sweetalert2.scss";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import Vue from "vue";
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);

export default {
  components: { BaseInput, TheFooter },
    props: {
        url: {
            require: false,
            default: '/signup'
        }
    },
  data() {
    return {};
  },
  created: function() {},
  methods: {},
  data() {
    return {
      loading: false,
        form: {
            username: "",
            password: ""
        },
        errors: {
            invalid: ""
        },
      tourId: "",
      tour: {
        title: "Tour",
        videoUrl: ""
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.tourId = this.$route.query.id;
      this.getTourVimeoId();
    }
  },
  methods: {
    getTourVimeoId() {
      this.$http.get("get_tour_data/" + this.tourId).then(resp => {
        this.tour.title = resp.data.name;
        this.tour.videoUrl = resp.data.vimeo_video_id;
      });
    },
      onSubmit() {
          this.loading = true;
          this.$http
              .post("user/login", {
                  email: this.form.username,
                  password: this.form.password
              })
              .then(resp => {
                  localStorage.setItem("hot-token", resp.data.token);
                  localStorage.setItem("hot-user", resp.data.role);
                  localStorage.setItem("hot-logged-user", resp.data.user_id);
                  localStorage.setItem("hot-user-full-name", resp.data.full_name);
                  let headers = {
                      authorization: "Bearer " + resp.data.token,
                      "content-type": "application/json"
                  };
                  this.$http.defaults.headers.authorization =
                      "Bearer " + resp.data.token;
                  let admin = "";
                  let level = "";

                  switch (resp.data.role) {
                      case "super-admin":
                          admin = "super_admin";
                          localStorage.setItem("hot-sidebar", admin);
                          this.$http
                              .post(
                                  "company/managerdata",
                                  {
                                      email: this.form.username
                                  },
                                  {headers}
                              )
                              .then(resp => {
                                  localStorage.setItem("hot-user-id", resp.data[0].id);
                                  this.$router.push("/dashboard");
                              });

                          break;
                      case "company-admin":
                          admin = "admin";
                          localStorage.setItem("hot-sidebar", admin);
                          this.$http
                              .post(
                                  "company/data",
                                  {
                                      email: this.form.username
                                  },
                                  {headers}
                              )
                              .then(resp => {
                                  if (resp.data.level) {
                                      localStorage.setItem("hot-company-level", "parent");
                                  } else {
                                      localStorage.setItem("hot-company-level", "child");
                                  }
                                  localStorage.setItem("hot-admin-id", resp.data.admin_id);
                                  localStorage.setItem("hot-user-id", resp.data[0].id);
                                  localStorage.setItem("hot-company-name", resp.data[0].name);
                                  this.$router.push("/dashboard");
                              });
                          break;
                      case "manager":
                          admin = "manager";
                          localStorage.setItem("hot-sidebar", admin);
                          this.$http
                              .post(
                                  "company/managerdata",
                                  {
                                      email: this.form.username
                                  },
                                  {headers}
                              )
                              .then(resp => {
                                  localStorage.setItem("hot-user-id", resp.data[0].id);
                                  localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                  localStorage.setItem(
                                      "hot-user-number",
                                      resp.data[0].phone_num
                                  );
                                  localStorage.setItem(
                                      "hot-user-2fa",
                                      resp.data[0].is_2f_authenticated
                                  );
                                  this.$router.push("/dashboard");
                              });
                          break;
                      case "employee":
                          admin = "employee";
                          localStorage.setItem("hot-sidebar", admin);
                          this.$http
                              .post(
                                  "employees/user_data",
                                  {
                                      user_name: this.form.username
                                  },
                                  {headers}
                              )
                              .then(resp => {
                                  localStorage.setItem("hot-user-id", resp.data[0].id);
                                  localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                  localStorage.setItem(
                                      "hot-user-number",
                                      resp.data[0].phone_num
                                  );
                                  localStorage.setItem(
                                      "hot-user-2fa",
                                      resp.data[0].is_2f_authenticated
                                  );
                                  if (resp.data[0].employee_status == 0) {
                                      this.errors.invalid = "Account is Deactivated by Admin..!!";
                                  } else {
                                      this.$router.push("/dashboard");
                                  }
                              });
                          break;
                      case "sub-admin":
                          admin = "sub_admin";
                          localStorage.setItem("hot-sidebar", admin);
                          this.$http
                              .post(
                                  "company/managerdata",
                                  {
                                      email: this.form.username
                                  },
                                  {headers}
                              )
                              .then(resp => {
                                  localStorage.setItem("hot-user-id", resp.data[0].id);
                                  this.$router.push("/dashboard");
                              });

                          break;
                      default:
                          //this.$router.push("/login");
                          this.errors.invalid = "Not Valid..!!";
                  }
              })
              .catch(function (error) {
                  let errorText = "Something went wrong! Please try again later.";
                  if (error.response && error.response.status === 422) {
                      errorText = error.response.data.message;
                  }
                  self.processing = false;
                  Swal.fire({
                      title: "Error!",
                      html: errorText,
                      icon: "error"
                  });
              })
              .finally(() => (this.loading = false));
      }
  }
};
</script>
<style scoped>
body,
html {
  height: 100%;
}
.form-section {
  background-color: #e4e8e8;
}
.login-section {
  background-color: #ececf9;
  padding: 0px;
}
.balloonimgnew{
    padding-top: 40px;
    padding-bottom: 15px;
    background-size: cover;
    background-position: right;
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    min-height: 87vh;
}
.iframe-broder{
  /* width:150px;
  height:300px; */
  border-radius: 5px;
 }
 .ctm-btn {
  background-color: #a8312d;
  color: #ffffff;
  border-radius: 0 !important;
  font-size: 17px;
  margin-right: 5px;
}
.login-inputs {
  padding-top: 20px;
}
.login{
  margin-right: 0px !important;
}
.TourVideo_section {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 11px 3px #0000001a;
}


@media (min-width:320px) and (max-width:767px){

.col-md-8.login-inputs.mb-3 {
	position: relative;
    right: -75px;
    width: 70%;
    top: -80px;
}

.header-section {
    min-height: 190px !important;
}

.banner-caption h2 {
        font-size: 22px;
    margin: 0;
}

.banner-caption {
    padding: 28px 0;
}

iframe.iframe-broder.text-center {
    height: auto;
}

body.vp-center {
    align-items: flex-start;
    justify-content: flex-start;
}

div#player {
    max-width: 100% !important;
}

.balloonimgnew {
    min-height: auto !important;
}

.balloonimgnew {
    height: auto !important;
}

.main-content.bg-color {
    min-height: 80vh;
}

ul.footer-link {
    margin: 30px;
    display: flex;
    justify-content: flex-start;
}

.header-section {
    min-height: 190px!important;
    height: 190px;
}
iframe.iframe-broder.text-center {
    max-height: 200px;
    min-height: 200px;
}
.social-link, .footer-link{
justify-content:center;
}
}

@media (min-width:767px) and (max-width:1024px){
iframe.iframe-broder.text-center {
    max-height: 383px;
    min-height: 383px;
}
.balloonimgnew{
height:auto !important;
min-height:auto !important;
}
.social-link, .footer-link{
justify-content:center;
}
}

.logo_img {
    width: 100px;
    padding: 10px;
}

.login-text {
    position: absolute !important;
}

.signup-text {
    position: absolute;
    right: 130px;
    top: 20px;
    z-index: 9;
    font-weight: 700;
}


</style>
